/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import './assets/css/variable.scss';
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;600;800&display=swap');



html, body { height: 100%; }
body { margin: 0;  font-family: Manrope, "Helvetica Neue", sans-serif; background-color: #EDEDED!important; }

:root {
      //background
      --bg-color: #0094ff; 

      //text
      --text-white: #ffffff;
      --text-black : #000000;
      --text-blue : #0094ff;
      --text-green : #14a468;
  
  
      //btn 
  
      --btn-primary : #0094ff;
      --btn-secondary : #dc3545;
      --btn-disabled : #dee2e6; 
  
      //gradeient 
  
      --gradient-1 : #003a8c;
      --gradient-2 : #40a9ff;

  }
  
  .dark-theme {
    //background
    --bg-color: #1B2531; 

    //text
    --text-white: #ffffff;
    --text-black : #000000;
    --text-blue : #0094ff;
    --text-green : #14a468; 


    //btn 

    --btn-primary : #0094ff;
    --btn-secondary : #dc3545;
    --btn-disabled : #dee2e6;

     //gradeient 
     --gradient-1 : #0094ff;
     --gradient-2 : #14a468;
  } 

  .inno-theme {
    //background
    --bg-color: #0094ff; 

    //text
    --text-white: #ffffff;
    --text-black : #000000;
    --text-blue : #0094ff;
    --text-green : #14a468;


    //btn
    --btn-primary : #0094ff;
    --btn-secondary : #dc3545;
    --btn-disabled : #dee2e6; 

    //gradeient 
    --gradient-1 : #003a8c;
    --gradient-2 : #40a9ff;
  }

  .green-theme {

    //background
    --bg-color: #14a468; 

    //text
    --text-white: #ffffff;
    --text-black : #000000;
    --text-blue : #0094ff;
    --text-green : #14a468;


    //btn 

    --btn-primary : #14a468;
    --btn-secondary : #dc3545;
    --btn-disabled : #dee2e6; 

     //gradeient 

     --gradient-1 : #0094ff;
     --gradient-2 : #14a468;
  }
  

  .purple-theme {

    //background
    --bg-color: #7f4cf4; 

    //text
    --text-white: #ffffff;
    --text-black : #000000;
    --text-blue : #0094ff;
    --text-green : #14a468;
    

    //btn 
    --btn-primary : #7f4cf4;
    --btn-secondary : #dc3545;
    --btn-disabled : #dee2e6; 

     //gradeient 
     --gradient-1 : #7f4cf4;
     --gradient-2 : #8dc2ee;
  }

  .blu-theme {

    //background
    --bg-color: #215fac; 

    //text
    --text-white: #ffffff;
    --text-black : #000000;
    --text-blue : #0094ff;
    --text-green : #14a468;
    

    //btn 
    --btn-primary : #215fac;
    --btn-secondary : #dc3545;
    --btn-disabled : #dee2e6; 

     //gradeient 
     --gradient-1 : #215fac;
     --gradient-2 : #878182;
  }




  .inVisible {
    visibility: hidden !important
  }
  
  .example-spacer {
    flex: 1 1 auto;
  } 


  
  .mat-mdc-flat-button.mat-primary,
  .mat-mdc-raised-button.mat-primary,
  .mat-mdc-fab.mat-primary,
  .mat-mdc-mini-fab.mat-primary {
    &:not(:disabled){
      background-color: $btn-primary-bg !important;
      color: $btn-primary-color ;
    }
    box-shadow: none;
    font-weight: normal;
    padding: $btn-padding ;
    min-width: 100px !important;
    font-size: 15px;
  }
  
  .mat-mdc-raised-button.mat-button-disabled{
    color :#10b5df42!important;
  }
  
  .mat-mdc-flat-button.mat-secondary,
  .mat-mdc-raised-button.mat-secondary,
  .mat-mdc-fab.mat-secondary,
  .mat-mdc-mini-fab.mat-secondary {
    &:not(:disabled){
      background-color: $btn-secondary-bg !important;
      color: $btn-secondary-color !important;
    }
    box-shadow: none;
    font-weight: normal;
    padding: $btn-padding  !important;
    min-width: 100px !important;
    font-size: 15px;
  }
  
  .mat-mdc-stroked-button.mat-primary {
    color: #242424;
    border-color: #242424;
    box-shadow: none;
    font-weight: normal;
    min-width: 100px !important;
    font-size: 15px;
  }
  
  .mat-input-element:disabled{
      color:rgba(0, 0, 0, 0.9) !important;
  }
  
  
 
 
  .toast-bootstrap-compatibility-fix {
    opacity:1;
  }

  .px-2 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
  }
  
 

  .ps {
      -ms-overflow-style: none;
      -ms-touch-action: auto;
      overflow: hidden!important;
      overflow-anchor: none;
      touch-action: auto;
  }
  
  
 
  textarea {
      resize: vertical;
      margin: 0;
      font-family: Manrope;
      font-size: inherit;
      line-height: inherit;
  }
  
  

  
  /* You can add global styles to this file, and also import other style files */
  
  /* Importing Bootstrap SCSS file. */
  // @import '~bootstrap/scss/bootstrap';
  body {
      font-family: Manrope, Helvetica, arial, sans-serif !important;
      overflow: hidden !important;

    }
  
  
 
 
    .p-s{
      position: relative;
    } 
  
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    } 
  
    .container-section {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: row wrap;
      width: 100%;
      height: calc(100vh - 80px);
      

  } 
  
  .btn-color{
    background-color: #003A8C !important;
    color:#fff !important;
  } 
  
 
  
    /* CSS for small screens */
    @media (max-width: 767px) {
      .title-text{
        font-size: 15px;
      }
      .upload-dialog{
       width: 80%;
      }
      .lds-dual-ring:after {
        width: 50px;
        height: 50px;
      }
      @keyframes lds-dual-ring {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    
    }
    
    /* CSS for medium screens */
    @media (min-width: 768px) and (max-width: 1023px) {
      .title-text{
        font-size: 19px;
      }
      .upload-dialog{
        width: 50%;
        min-height: 175px;
        font-size: 17px;
        letter-spacing: 1px;
      }
    
    }
    
    
    /* CSS for large screens */
    @media (min-width: 1024px) {
      .upload-dialog{
        width: 27%;
        min-height: 175px;
        font-size: 17px;
        letter-spacing: 1px;
      }
    
    } 


 
    .disable{
      pointer-events: none;
    } 

.loader-container {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #dae8f788;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);
}

.ring-loader {
  width: 65px;
  aspect-ratio: 1;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-color: #ccc #0000;
  animation: l16 1s infinite linear;
}
.ring-loader::before,
.ring-loader::after {    
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
}
.ring-loader::before {
  border-color: #003A8C #0000;
  animation: inherit; 
  animation-duration: .5s;
  animation-direction: reverse;
}
.ring-loader::after {
  margin: 8px;
}
@keyframes l16 { 
  100%{transform: rotate(1turn)}
}

.loading-text {
  font-size: 20px;
  margin-top: 10px;
  color: #003a8c;
  font-weight: 600;
  font-family: Manrope;
  text-shadow: 0px 0px 20px #bcbcbc;
}

  .custom-toast {
    position: absolute;
    top: 90px;
    right: 10px;
  }

  @keyframes colorChange {
    to { background-color: transparent; }
  }

  .info-dialog{
    width: 540px;
    // min-height: 550px;
    font-size: 17px;
    letter-spacing: 1px;
    border-radius: 5px;
    overflow: hidden;
  }

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #878787;
  border-radius: 5px;
}

.custom-dialog-class{
  border-radius: 20px;
  overflow: hidden;
}


////gen



.g-container{
  display: flex;
  width: 100%;
 
}

.sidenav {
  width: 25%;
  height: calc( 100vh - 100px);
  background-color: #fff; 
  margin: 10px;
  box-shadow: 2px 2px 10px 2px #00000040;
  border-radius: 15px;
}

.historynav {
width: 20%;
height: calc( 100vh - 100px);
background-color: #fff; 
margin: 10px;
box-shadow: 2px 2px 10px 2px #00000040;
border-radius: 15px;
}


.main-container {
  width: 75%;
  height: calc( 100vh - 100px);
  background-color: #f7f7f7; 
  margin: 10px;
  box-shadow: 2px 2px 10px 2px #00000040;
  border-radius: 15px;

}

.topics {
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  background: linear-gradient(to left, var(--gradient-1), var(--gradient-2));
  color: #fff;
  padding: 10px;
  font-size: 18px;
}

.output-wrapper {
  display: flex;
  align-items: center;
}

.sidebody{
margin-top: 20px;
} 

.example-full-width{
  width: 100%;
}


.side-body {
  height: calc(100% - 130px);
  position: relative;
  overflow: hidden;
  padding: 20px;
}

.gen-bt{
min-width: 100px;
}

.item-body {
  height: calc(100% - 130px);
  position: relative;
  overflow: auto;
  padding: 20px;
  background-color: #F7F7F7;
  border-radius: 15px;
} 

.n-item-body {
height: calc(100% - 80px);
}

.centered-content {
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 100%;
}
.chat-footer {
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.no-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;

  label {
    font-size: 1.5rem;
    font-weight: 600;
    opacity: 0.5;
  }

  .upload-image {
    width: 30%;
  }
}

.n-item-body {
  height: calc(100% - 80px);
}

.heading{
  display: flex;
  justify-content: flex-end;
  border-radius: 10px;
  background: #fff;
  color: #000000;
  padding: 10px;
  font-size: 18px;
}

.send-button {
  background-color: var(--btn-primary);
  color: #ffffff;
} 

.output-wrapper {
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}